@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
.react-daterange-picker__wrapper {
  @apply border-green-700 bg-white rounded-lg p-2;
}
.overflow-y-custom {
  overflow-y: auto;
  padding-right: 0px;
}
/* overflow-y width */
.overflow-y-custom::-webkit-scrollbar {
  width: 5px;
  height: 0px;
  padding-right: 0px;
}
/* overflow-y Track */
.overflow-y-custom::-webkit-scrollbar-track {
  background: none;
  padding-right: 0px;
}
/* overflow-y Handle */
.overflow-y-custom::-webkit-scrollbar-thumb {
  background: rgb(255 193 7 / 0.9);
  border-radius: 15px !important;
  padding-right: 0px;
}
